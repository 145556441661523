import { ref, readonly, computed, watch } from 'vue';
import { getConfiguration, getProduct } from './products';
import {
  ADD_TO_CART,
  ADD_CONFIGURATION_TO_CART,
  ADD_TO_CONFIGURATION,
  REMOVE_FROM_CONFIGURATION,
  REMOVE_FROM_CART,
  STRIP_FROM_CART,
  CHECKOUT_REQUEST,
  CHECKOUT_FAILURE,
} from './ActionTypes';
import { setStorage, getStorage } from './localStore';

let cart = ref({
  addedIds: [],
  quantityById: {},
  configurationById: {},
});

const addedIds = (state = cart.value.addedIds, action) => {
  switch (action.type) {
    case ADD_TO_CART:
    case ADD_TO_CONFIGURATION: {
      const isConfiguration = action.type === ADD_TO_CONFIGURATION;
      const id = isConfiguration ? action.configurationId : action.productId;

      if (state.indexOf(id) !== -1) {
        return state;
      }
      return [...state, id];
    }
    case REMOVE_FROM_CART:
      if (cart.value.quantityById[action.productId] - 1) {
        return state;
      }

      return state.filter((id) => action.productId !== id);

    case STRIP_FROM_CART: {
      return state.filter((id) => action.productId !== id);
    }
    default:
      return state;
  }
};

const quantityById = (state = cart.value.quantityById, action) => {
  switch (action.type) {
    // Add the configuration only when click add to cart
    case ADD_TO_CART:
    case ADD_CONFIGURATION_TO_CART: {
      const isConfiguration = action.type === ADD_CONFIGURATION_TO_CART;
      const id = isConfiguration ? action.configurationId : action.productId;

      return {
        ...state,
        [id]: isConfiguration
          ? state[id]
            ? state[id]
            : 1
          : (state[id] || 0) + 1,
      };
    }
    case REMOVE_FROM_CART: {
      const { productId } = action;
      return state[productId] - 1
        ? { ...state, [productId]: state[productId] - 1 }
        : {
            ...Object.entries(state)
              .filter(([key, value]) => Number(key) !== productId)
              .reduce((obj, [__key__, __value__]) => {
                obj[__key__] = __value__;

                return obj;
              }, {}),
          };
    }
    case STRIP_FROM_CART: {
      // return {
      //   ...Object.keys(state)
      //     .filter((key) => key !== action.productId)
      //     .reduce((obj, id) => (obj[id] = state[id]), {}),
      // };

      return {
        ...Object.keys(state)
          .filter((key) => key !== action.productId.toString())
          .reduce((obj, id) => {
            obj[id] = state[id];
            return obj;
          }, {}),
      };
    }
    default:
      return state;
  }
};

const configurationById = (state = cart.value.configurationById, action) => {
  switch (action.type) {
    case ADD_TO_CONFIGURATION: {
      const {
        configurationId,
        option: { part, id },
      } = action;
      return {
        ...state,
        [configurationId]: { ...state[configurationId], [part]: id },
      };
    }
    case REMOVE_FROM_CONFIGURATION: {
      const {
        configurationId,
        option: { part },
      } = action;

      return {
        ...state,
        [configurationId]: {
          ...Object.entries(state[configurationId])
            .filter(([key, value]) => key !== part)
            .reduce((obj, [__key__, __value__]) => {
              obj[__key__] = __value__;

              return obj;
            }, {}),
        },
      };
    }
    case STRIP_FROM_CART: {
      return {
        ...Object.keys(state)
          .filter((key) => key !== action.productId.toString())
          .reduce((obj, id) => {
            obj[id] = state[id];
            return obj;
          }, {}),
      };
    }
    default:
      return state;
  }
};

const cartReducer = (state = cart.value, action) => {
  switch (action.type) {
    case CHECKOUT_REQUEST:
      return cart;
    case CHECKOUT_FAILURE:
      return action.cart; /*check if this will be the correct case answer */
    default:
      return {
        addedIds: addedIds(state.addedIds, action),
        quantityById: quantityById(state.quantityById, action),
        configurationById: configurationById(state.configurationById, action),
      };
  }
};

export const addToCart = (productId) => {
  const newCart = cartReducer(cart.value, {
    type: ADD_TO_CART,
    productId,
  });
  cart.value = newCart;

  /* We might do a API call to validate before, to signalize success we return a value so the toast can get shown  */

  return { success: true };
};

export const addConfigurationToCart = (CID) => {
  const newCart = cartReducer(cart.value, {
    type: ADD_CONFIGURATION_TO_CART,
    configurationId: CID,
  });

  cart.value = newCart;
};

export const getQuantity = computed(() => (productId) =>
  cart.value.quantityById[productId] || 0,
);

export const getAddedIds = () => cart.value.addedIds;

export const removeFromCart = (productId) => {
  const newCart = cartReducer(cart.value, {
    type: REMOVE_FROM_CART,
    productId,
  });
  cart.value = newCart;

  return { success: true };
};

export const addToConfiguration = (option, configurationId = '') => {
  if (!configurationId) {
    configurationId =
      '_$' +
      Math.random()
        .toString(36)
        .substr(2, 9);
  }

  const newCart = cartReducer(cart.value, {
    type: ADD_TO_CONFIGURATION,
    configurationId,
    option,
  });

  cart.value = newCart;

  return { success: true, configurationId };
};

export const removeFromConfiguration = (option, configurationId) => {
  const newCart = cartReducer(cart.value, {
    type: REMOVE_FROM_CONFIGURATION,
    configurationId,
    option,
  });

  cart.value = newCart;

  return { success: true, configurationId };
};

export const stripFromCart = (id) => {
  const newCart = cartReducer(cart.value, {
    type: STRIP_FROM_CART,
    productId: id,
  });

  cart.value = newCart;

  return;
};

export const getConfigurationTotal = computed(() => (CID) => {
  return Object.values(cart.value.configurationById[CID]).reduce(
    (total, id) =>
      total +
      Number(getConfiguration(id).price) * (cart.value.quantityById[CID] || 1),
    0,
  );
});

export const getCatalogueTotal = computed(() => () =>
  (cart.value.addedIds || [])
    .filter((id) => !isNaN(id))
    .reduce(
      (total, id) =>
        total + Number(getProduct(id).price) * cart.value.quantityById[id],
      0,
    ),
);

export const getPendingConfigurations = () =>
  Object.keys(cart.value.configurationById);

export const initCart = function(app) {
  let expirationDays = 1;
  const storedCart = JSON.parse(getStorage());

  if (!storedCart) return;
  let now = Date.now();
  let { date, data } = storedCart;

  if ((now - date) / 1000 / 60 / 60 / 24 < expirationDays) {
    cart.value = data;
  }
};

watch(
  () => cart,
  (cart, oldCart) => {
    setStorage(cart.value);
  },
  { deep: true },
);

export default readonly(cart);
