import { ref, readonly, computed } from 'vue';
import { RECEIVE_PRODUCTS } from './ActionTypes';

const products = ref({
  configuratorProductsById: {},
  catalogueProductsById: {},
});

const byId = (state, action) => {
  switch (action.type) {
    case RECEIVE_PRODUCTS: {
      let { key } = action,
        products = action.products[key] || [];
      return {
        ...state,
        ...products.reduce((obj, product) => {
          obj[product.id] = product;
          return obj;
        }, {}),
      };
    }
    default:
      return state;
  }
};

const productReducer = (action) => {
  let state = products.value;
  let { type, key } = action;
  let isConfiguration = key === 'CONFIGURATOR';
  switch (type) {
    case RECEIVE_PRODUCTS:
      return {
        configuratorProductsById: byId(
          state.configuratorProductsById,
          isConfiguration ? action : {},
        ),
        catalogueProductsById: byId(
          state.catalogueProductsById,
          isConfiguration ? {} : action,
        ),
      };
  }
};

export const receiveProducts = (action) => {
  const newProducts = productReducer({
    type: RECEIVE_PRODUCTS,
    key: action.key,
    products: action.products,
  });

  products.value = newProducts;
  return Promise.resolve();
};

export const getConfiguration = (id) => {
  return products.value.configuratorProductsById[id];
};

export const getProduct = (id) => products.value.catalogueProductsById[id];

export default readonly(products);
