<template>
  <div
    @mouseenter="onTrapEnter"
    @mouseleave="onTrapLeave"
    class="relative lg:mb-6 product-card"
  >
    <div class="relative ">
      <figure
        class="relative h-48 lg:h-56 p-8 bg-white mb-4 overflow-hidden transition duration-150"
      >
        <img
          class="h-full w-full object-contain"
          :src="product.images[0].src"
          alt=""
        />
      </figure>
      <cat-card-hover
        :product="product"
        :category="category"
        :show="isHovering"
      />
    </div>
    <div class="text-sm text-gray-600 leading-4">
      {{ category }}
    </div>
    <div
      class="float-left whitespace-nowrap overflow-ellipsis overflow-hidden w-8/12 md:w-9/12"
      :title="product.name"
    >
      {{ product.name }}
    </div>
    <div class="float-right">{{ product.price }}&euro;</div>
  </div>
</template>

<script>
import CatCardHover from './CatCardHover';

export default {
  name: 'CatalogueCard',
  components: {
    CatCardHover,
  },
  setup() {},
  props: {
    product: {
      type: Object,
      required: true,
    },
    isScrolling: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
  },
  inject: ['showNewItem'],
  data() {
    return {
      isHovering: false,
      shouldRelease: true,
    };
  },
  computed: {
    category: function() {
      return this.product.categories.filter(
        ({ name }) => name !== 'Catalogue',
      )[0]['name'];
    },
  },
  methods: {
    handleCartAction() {
      this.addToCart(this.product);
      this.showNewItem(this.product);
    },
    onTrapEnter() {
      if (this.isScrolling) return;
      setTimeout(() => {
        this.isHovering = true;
      }, 160);
    },
    onTrapLeave() {
      if (this.shouldRelease) {
        setTimeout(() => {
          this.isHovering = false;
        }, 160);
      }
    },
  },
};
</script>

<style lang="scss">
.move-up-enter-active,
.move-up-leave-active {
  transition: all 215ms ease-in-out;
}

.move-up-enter-from,
.move-up-leave-to {
  transform: translateY(100%);
}
</style>
