const storage = localStorage;

const identifier = '__CART__';

export const setStorage = (data) => {
  return storage.setItem(
    identifier,
    JSON.stringify({
      date: Date.now(),
      data,
    }),
  );
};

export const getStorage = () => storage.getItem(identifier);
