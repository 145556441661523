<template>
  <section class="block builder-visual">
    <div style="max-width: 1024px" class="absolute inset-0 mx-auto">
      <figure
        id="build-img"
        class="relative flex items-center h-full justify-center overflow-hidden"
      >
        <transition name="switch">
          <div
            key="1"
            v-if="isFrontview"
            class="absolute inset-0 switch-item-one"
          >
            <img
              class="absolute inset-0 builder-img-main"
              src="@/assets/img/configurator/8bit/00_base_1210mk2_VG+.png"
              alt=""
            />
            <transition-group @enter="enter" @leave="leave" :css="false">
              <img
                v-for="src in imageMap.front.flat()"
                :key="src"
                :src="imageUrl(src, false)"
                class="absolute inset-0"
                alt=""
              />
            </transition-group>
          </div>
          <div v-else key="2" class="absolute inset-0 switch-item-two">
            <img
              v-for="(src, index) in imageMap.top.flat()"
              :key="index"
              :src="imageUrl(src, true)"
              class="absolute inset-0 builder-img-main"
              alt=""
            />
          </div>
        </transition>
        <div :style="figure_box" class="absolute">
          <div class="absolute bottom-3 left-3">
            <p>
              <animate-price
                :rawTotal="$attrs.total"
                v-slot="{ animatedTotal }"
              >
                <span
                  v-if="animatedTotal"
                  class="block text-3xl bottom-3 left-3"
                  >{{ animatedTotal }}&euro;</span
                >
              </animate-price>
            </p>
          </div>
          <div class="absolute bottom-3 right-3">
            <button
              @click="isFrontview = !isFrontview"
              class="border border-black px-3 py-1 bg-white"
            >
              Switch view
            </button>
          </div>
        </div>
      </figure>
    </div>
  </section>
</template>

<script>
import {
  ref,
  computed,
  reactive,
  nextTick,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import gsap from 'gsap';

const frontImages = require.context(
  '../../assets/img/configurator',
  false,
  /\.png$/,
);
const topImages = require.context(
  '../../assets/img/configurator/_topview',
  false,
  /\.png$/,
);
export default {
  name: 'ConfigVisual',
  props: {
    imageMap: {
      type: Object,
      required: true,
    },
  },
  setup() {
    let figure_box = reactive({
      width: null,
      height: 0,
    });

    const placeElement = (width) => {
      let height = width * 0.75;

      figure_box.width = width + 'px';
      figure_box.height = height + 'px';
    };

    const calculateBox = () => {
      window.requestAnimationFrame(() => {
        let width = document.querySelector('.builder-img-main').offsetWidth;
        placeElement(width);
      });
    };
    onMounted(async () => {
      await nextTick();
      let width = document.querySelector('.builder-img-main').offsetWidth;
      placeElement(width);
      window.addEventListener('resize', calculateBox);

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 100);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', calculateBox);
    });
    return { isFrontview: ref(true), figure_box };
  },

  methods: {
    enter: (el, done) => {
      gsap.from(el, { opacity: 0, onComplete: done, duration: 0.233 });
    },
    leave: (el, done) => {
      gsap.to(el, { opacity: 0, onComplete: done, duration: 0.233 });
    },
    imageUrl: function(path, isTop = false) {
      let $path = './' + path;
      return isTop ? topImages($path) : frontImages($path);
    },
  },
};
</script>

<style lang="scss">
.builder-visual {
  position: relative;
  left: 0;
  right: 0;
  height: 40vh;
  margin-bottom: 0 !important;

  & img {
    height: 100%;
    object-fit: contain;
    margin: auto;
  }
}

@media only screen and (min-width: 1024px) {
  $drawer-width: 440px;
  .builder-visual {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: $drawer-width;
    width: auto;
    height: 100%;
    margin-bottom: 0 !important;

    & img {
      height: 100%;
      padding: 21px 0;
      margin: auto;
    }
  }
}
</style>
