<template>
  <div class="flex items-center">
    <input
      type="checkbox"
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
      class="mr-3"
    />
    <label v-if="label" v-html="label"></label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
input[type='checkbox'] {
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #cacece;
  padding: 9px;

  display: inline-block;
  position: relative;
}

input[type='checkbox']:checked {
}

input[type='checkbox']:checked:after {
  content: 'x';
  font-size: 16px;
  position: absolute;
  bottom: -1px;
  left: 5px;
}
</style>
