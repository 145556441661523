<template>
  <transition name="hover-trap">
    <template v-if="show">
      <div
        class="hover-box flex flex-col bg-primary border border-black border-opacity-30 shadow-lg overflow-hidden"
      >
        <header class="w-full relative">
          <figure class="relative" style="padding: 27.5% 0">
            <div class="absolute inset-0 bg-white py-6">
              <img class="h-full mx-auto" :src="product.images[0].src" alt="" />
            </div>
          </figure>
        </header>
        <article class="flex flex-col flex-1 p-3">
          <h4 class="text-gray-500">{{ category }}</h4>
          <h2 class="text-xl">{{ product.name }}</h2>
          <div class="relative flex flex-1 flex-col pt-3">
            <transition name="switch">
              <template v-if="!isInCart">
                <div key="1" class="flex flex-col flex-1 switch-item-one">
                  <p class="flex-1">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Sequi consequuntur molestias, fugiat delectus voluptatibus,
                    eaque fugit repellat dicta optio eius dolores amet. Dolores
                    neque possimus deleniti quam magni reprehenderit aperiam!
                  </p>
                  <footer class="flex justify-between items-end">
                    <div>
                      <span class="text-gray-500 text-sm">Price</span>
                      <span class="text-2xl"
                        >{{ product.regular_price }}&euro;</span
                      >
                    </div>
                    <button
                      @click="onAddToCart(product.id)"
                      class="bg-white px-5 py-2"
                    >
                      Add to Cart
                    </button>
                  </footer>
                </div>
              </template>
              <template v-else>
                <div
                  key="2"
                  class="absolute inset-0 switch-item-two flex flex-col"
                >
                  <div class="flex-1">
                    <div
                      class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-3/4 pb-12"
                    >
                      <p class="mb-1 text-center">Quantity</p>
                      <div class="flex items-center self-baseline">
                        <button
                          @click="onRemoveFromCart(product.id)"
                          class="w-12 h-12 bg-white p-1 pointer-events-auto"
                        >
                          -
                        </button>
                        <div class="px-9 w-8 h-8 flex items-center">
                          <span class="absolute transform -translate-x-1">
                            {{ getQuantity(product.id) }}</span
                          >
                        </div>
                        <button
                          @click="onAddToCart(product.id)"
                          class="w-12 h-12 bg-white p-1 flex-grow-0 pointer-events-auto"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  <aside class="py-1 ">
                    <span class="bg-white px-2 py-1"
                      >The product is in your cart!</span
                    >
                  </aside>
                </div>
              </template>
            </transition>
          </div>
        </article>
      </div>
    </template>
  </transition>
</template>

<script>
import { toRefs, computed } from 'vue';
import cart, {
  addToCart,
  removeFromCart,
  getQuantity,
} from '@/composable/cart';

export default {
  name: 'CatalogueHover',
  inject: ['showNewItem'],
  setup(props) {
    let isInCart = computed(
      () => cart.value.addedIds.indexOf(props.product.id) !== -1,
    );

    return { cart, addToCart, removeFromCart, isInCart, getQuantity };
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onAddToCart(productId) {
      const { success } = this.addToCart(productId);
    },
    onRemoveFromCart(productId) {
      const { success } = this.removeFromCart(productId);
    },
  },
};
</script>
