import { createRouter, createWebHistory, RouterView } from 'vue-router';
import { defineComponent, h } from 'vue';
import Home from '../views/Home.vue';

const routeParent = defineComponent({
  render() {
    return h('div', {}, h(RouterView));
  },
});

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/legacy',
  },
  // {
  //   path: '/shop',
  //   name: 'Shop',
  //   redirect: '/shop/turntable-configurator',
  //   component: routeParent,
  //   children: [
  //     {
  //       path: '/shop/turntable-configurator',
  //       name: 'Turntable Configurator',
  //       component: () =>
  //         import(
  //           /** webpackChunkName 'turntable_configurator' */ '../views/TurntableConfigurator.vue'
  //         ),
  //     },
  //     {
  //       path: '/shop/catalogue',
  //       name: 'Catalogue',
  //       component: () =>
  //         import(/** webpackChunkName 'catalogue' */ '../views/Catalogue.vue'),
  //     },
  //     {
  //       path: '/shop/rentals',
  //       name: 'Rentals',
  //       component: () =>
  //         import(/** webpackChunkName 'rentals' */ '../views/Rentals.vue'),
  //     },
  //   ],
  // },
  // {
  //   path: '/service',
  //   name: 'Service',
  //   component: () =>
  //     import(/** webpackChunkName 'service' */ '../views/Service.vue'),
  // },
  {
    path: '/legacy',
    name: 'Legacy',
    component: () =>
      import(/** webpackChunkName 'legacy' */ '../views/Legacy.vue'),
  },
  // {
  //   path: '/terms',
  //   name: 'Terms',
  //   component: () =>
  //     import(/** webpackChunkName 'terms' */ '../views/TermsConditions.vue'),
  // },
  // {
  //   path: '/order',
  //   name: 'Order',
  //   redirect: '/order/cart',
  //   component: () =>
  //     import(
  //       /** wbpackChunkName 'checkout' */ './parents/checkout'
  //     ).then(({ checkoutParent }) => checkoutParent(RouterView)),
  //   children: [
  //     {
  //       path: '/order/cart',
  //       name: 'Cart',
  //       component: () =>
  //         import(/** webpackChunkName 'cart' */ '../views/Cart.vue'),
  //     },
  //     {
  //       path: '/order/checkout',
  //       name: 'Checkout',
  //       component: () =>
  //         import(/** webpackChunkName 'cart' */ '../views/Checkout.vue'),
  //     },
  //   ],
  // },
  // 404
  {
    path: '/:pathMatch(.*)*',
    redirect: '/legacy',
    // name: '404',
    // component: () => import(/** webpackChunkName "404" */ '../views/404.vue'),
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { top: 0 };
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// @todo

router.beforeEach((to, from) => {
  document.title = to.name + ' | ' + '1210berlin';
});

export default router;
