<template>
  <div class="relative">
    <img
      v-bind="$attrs"
      src="@/assets/img/configurator/8bit/00_base_1210mk2_VG+.png"
      alt=""
    />
    <img
      v-for="(src, index) in cleanImageMap.front.flat()"
      :key="index"
      :src="imageUrl(src, false)"
      class="absolute inset-0 w-full h-full object-contain"
      alt=""
    />
  </div>
</template>

<script>
const frontImages = require.context(
  '../../assets/img/configurator',
  false,
  /\.png$/,
);
const topImages = require.context(
  '../../assets/img/configurator/topview',
  false,
  /\.png$/,
);

export default {
  name: 'ConfigVisualThumb',
  props: {
    imageMap: {
      type: Object,
    },
  },

  computed: {
    cleanImageMap() {
      let { front, top } = this.imageMap;

      return {
        front: front.flat().filter((src) => src.includes('.png')),
        top: top.flat().filter((src) => src.includes('.png')),
      };
    },
  },

  methods: {
    imageUrl: function(path, isTop = false) {
      let $path = './' + path;
      return isTop ? topImages($path) : frontImages($path);
    },
  },
};
</script>
