<script>
import { toRefs, h, defineComponent, computed } from 'vue';

const DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const DAYS_IN_MONTH_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const IS_LEAP = (year) =>
  (year.value % 4 === 0 && year.value % 100 !== 0) || year.value % 400 === 0;

const FIRST_OF_WEEK = (year, month) =>
  new Date(year.value, month.value).getDay();

export default defineComponent({
  name: 'DaysOfMonth',
  props: {
    year: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
  },

  setup(props, ctx) {
    const { year, month } = toRefs(props);

    // let first = computed(() => FIRST_OF_WEEK(year, month));

    // let pre = computed(() =>
    //   Array.from(Array(first.value > 0 ? first.value : 0), (v, i) =>
    //     IS_LEAP(year)
    //       ? DAYS_IN_MONTH_LEAP[month.value ? month.value - 1 : month.value] - i
    //       : DAYS_IN_MONTH[month.value ? month.value - 1 : month.value] - i,
    //   )
    //     .map((x) => new Date(year.value, month.value - 1, x))
    //     .reverse(),
    // );

    let days = computed(() =>
      Array.from(
        Array(
          IS_LEAP(year)
            ? DAYS_IN_MONTH_LEAP[month.value]
            : DAYS_IN_MONTH[month.value],
        ),
        (_, i) => i + 1,
      ).map((x) => new Date(year.value, month.value, x)),
    );

    // let n = pre.value.length + days.value.length;

    // let post = computed(() =>
    //   n < 42
    //     ? Array.from(Array(42 - n), (_, i) => i + 1).map(
    //         (x) => new Date(year.value, month.value + 1, x),
    //       )
    //     : [],
    // );

    return () => ctx.slots.default({ days: [...days.value] });
  },
});
</script>
