import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Amplify, { Storage, API } from 'aws-amplify';

const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_cloud_logic_custom: [
    {
      name: '1210',
      endpoint: 'https://mjfwaf5kkd.execute-api.eu-central-1.amazonaws.com/dev',
      region: 'eu-central-1',
    },
  ],
  aws_cognito_identity_pool_id:
    'eu-central-1:6d843b10-0e97-405c-bbc0-2654726b1597',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_31JI2K0iv',
  aws_user_pools_web_client_id: '1fh3njibvoi9kp4ti43bvolr34',
  oauth: {},
  aws_user_files_s3_bucket:
    'finalfront0225c6b7ef2d47bf81a9316818d154b2183035-dev',
  aws_user_files_s3_bucket_region: 'eu-central-1',
};

Amplify.configure(awsmobile);

import '@/assets/main.css';

// utility

import { initCart } from '@/composable/cart';
import getScrollBarWidth from '@/util/scrollBarWidth';

// Global components

import MainCard from '@/components/cards/MainCard';
import BaseInput from '@/components/forms/BaseInput';
import BaseSelect from '@/components/forms/BaseSelect';
import BaseCheckbox from '@/components/forms/BaseCheckbox';
import AnimatePrice from '@/components/ui/AnimatePrice';

// directives

import { ClickOutside } from '@/directives/click-outside.js';

const app = createApp(App);
app
  .use(store)
  .use(router)
  .component('MainCard', MainCard)
  .component('BaseInput', BaseInput)
  .component('BaseSelect', BaseSelect)
  .component('BaseCheckbox', BaseCheckbox)
  .component('AnimatePrice', AnimatePrice)
  .directive('click-outside', ClickOutside)
  .mount('#app');

/**
 * Globals
 */

app.config.globalProperties.$scroll = (() => {
  const body = document.querySelector('body');
  let isBlocked = false;

  document.documentElement.style.setProperty(
    '--scrollbar-width',
    getScrollBarWidth() + 'px',
  );

  return {
    block: () => {
      if (isBlocked) return;
      if (!isBlocked) {
        body.classList.add('overflow-hidden');
        isBlocked = true;
      }
    },
    unblock: () => {
      if (isBlocked) {
        body.classList.remove('overflow-hidden');
        isBlocked = false;
      }
    },
  };
})();

app.config.globalProperties.$storage = Storage;

app.config.globalProperties.$api = API;

app.config.globalProperties.$settings = {
  api: {
    name: '1210',
  },
};

app.provide('storage', app.config.globalProperties.$storage);

window.addEventListener('load', initCart(app), false);
