<script>
import { h } from 'vue';

export default {
  inheritAttrs: false,
  props: {
    day: {
      type: Date,
      required: true,
    },
  },
  setup(props, { attrs, emit }) {
    return () =>
      h(
        'td',
        {
          class: ['drp-day', { disabled: props.day <= attrs.start }],
          onClick: () => emit('update:selected', props.day),
        },
        props.day.getDate(),
      );
  },
};
</script>

<style lang="scss">
.drp-day.disabled {
  @apply text-gray-400;
  cursor: not-allowed !important;
  pointer-events: none;

  &:hover {
    background-color: transparent !important;
  }
}
</style>
