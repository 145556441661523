import { computed, watchEffect, reactive } from 'vue';
import gsap from 'gsap';

export default {
  props: {
    rawTotal: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { slots }) {
    let data = reactive({
      tweenedNumber: 0,
    });

    watchEffect(() =>
      gsap.to(data, {
        duration: 0.5,
        tweenedNumber: props.rawTotal,
      }),
    );

    let animatedTotal = computed(() => data.tweenedNumber);

    return () =>
      slots.default({
        animatedTotal: animatedTotal.value.toFixed(2),
      });
  },
};
