<template>
  <component :is="LayoutMain">
    <template #header>
      <twelve-header></twelve-header>
    </template>
    <router-view v-slot="{ Component }">
      <transition name="throttle" mode="in-out">
        <component :is="Component"></component>
      </transition>
    </router-view>
    <template #footer>
      <twelve-footer></twelve-footer>
    </template>
  </component>
  <transition name="appear">
    <template v-if="restLoader.show">
      <RestLoader :messages="restLoader.messages" />
    </template>
  </transition>
</template>

<script>
import RestLoader from '@/components/ui/RestLoader';
import { LayoutMain, TwelveHeader, TwelveFooter } from '@/layout';

const isLarge = () => {
  if (typeof document === 'undefined') return 0; // SSR

  return (
    Math.max(document.documentElement.clientWidth, window.innerWidth || 0) >
    1024
  );
};

export default {
  setup() {
    return { LayoutMain };
  },
  components: {
    LayoutMain,
    TwelveHeader,
    TwelveFooter,
    RestLoader,
  },
  provide() {
    return {
      isLg: isLarge(),
      showNewItem: this.showNewItem,
      restLoader: {
        load: (messages) => this.showRestLoader(messages),
        stop: () => (this.restLoader.show = false),
      },
    };
  },
  data() {
    return {
      newItemBox: {
        show: false,
        item: {},
      },
      restLoader: {
        show: false,
        messages: [],
      },
    };
  },
  methods: {
    showNewItem(item) {
      this.newItemBox.item = item;
      this.newItemBox.show = true;

      setTimeout(() => {
        this.newItemBox.show = false;
      }, 5000);
    },
    showRestLoader(messages) {
      this.restLoader.messages = messages;
      this.restLoader.show = true;
    },
  },
};
</script>

<style lang="scss">
.throttle-leave-active {
  transition: all 0.21s;
}

* {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: rgb(88, 95, 95);
  }
  &::-webkit-scrollbar-thumb {
    background: #000;
  }
}

.appear-enter-active,
.appear-leave-active {
  transition: all 300ms ease;
}

.appear-enter-from,
.appear-leave-to {
  opacity: 0;
  right: 20px;
}

.switch-enter-active,
.switch-leave-active {
  transition: all 232ms ease;
  &.switch-item-two {
    position: absolute;
  }
}

.switch-leave-to {
  &.switch-item-one {
    transform: translateX(-100%);
  }
  &.switch-item-two {
    transform: translateX(100%);
  }
}

.switch-enter-from {
  &.switch-item-one {
    transform: translateX(-100%);
  }
  &.switch-item-two {
    transform: translateX(100%);
  }
}

.switch-vertical-enter-active,
.switch-vertical-leave-active {
  transition: all 233ms ease !important;
  position: absolute;
  &.switch-vertical-two {
    position: absolute !important;
  }
}

.switch-vertical-leave-to {
  transition: all 233ms ease !important;
  &.switch-vertical-one {
    transform: translateY(-100%);
    opacity: 0;
  }
  &.switch-vertical-two {
    transform: translateY(100%);
    opacity: 0;
  }
}

.switch-vertical-enter-from {
  transition: all 233ms ease !important;
  &.switch-vertical-one {
    transform: translateY(100%);
    opacity: 0;
  }
  &.switch-vertical-two {
    transform: translateY(-100%);
    opacity: 0;
  }
}
</style>
