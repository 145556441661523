import baseConfig from '@/static/configurator_config.json';
import { Storage } from 'aws-amplify';
import { readonly } from 'vue';

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const TIMEOUT = 100;

class DataStore {
  constructor() {
    this.baseConfig = baseConfig;
  }

  async getBaseConfiguration() {
    let config = window.__BASE_CONFIG__ || [...this.baseConfig];

    if (window.__BASE_CONFIG__) {
      console.log('getting items from window...');
    }

    await timeout(TIMEOUT);
    window.__BASE_CONFIG__ = readonly(config);
    return config;
  }

  async getConfiguratorItems() {
    let products;

    if (window.__CONFIGURATOR_PRODUCTS__) {
      products = window.__CONFIGURATOR_PRODUCTS__;
    } else {
      let res = await Storage.get('configurator-items.json', {
        download: true,
      });
      products = await new Response(res.Body).json();
    }
    console.log('Putting configurations to window');
    window.__CONFIGURATOR_PRODUCTS__ = products;

    return products;
  }
  async getConfigurationPresets() {
    const presets = [
      {
        title: 'Berghain Memories',
        price: 699,
        ids: { Model: 1488, Color: 1500, Appearance: 1496 },
      },
      {
        title: 'Vertigo',
        price: 699,
        ids: { Model: 1492, Color: 1502, Appearance: 1494, Slipmat: 1417 },
      },
      {
        title: 'Ziggy Stardust',
        price: 699,
        ids: {
          Model: 1488,
          Color: 1500,
          Appearance: 1494,
          Cartridge: 1405,
          Feet: 1457,
          Slipmat: 1419,
        },
      },
      {
        title: 'Robert Johnson',
        price: 849,
        ids: {
          Model: 1488,
          Color: 1502,
          Appearance: 1496,
          Cartridge: 1393,
          Slipmat: 1417,
          Feet: 1459,
        },
      },
    ];
    await timeout(TIMEOUT);

    return presets;
  }
}

export default new DataStore();
