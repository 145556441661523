<template>
  <div class="flex flex-wrap gap-6 ">
    <div
      :class="isTight ? 'w-sixth' : 'w-fourth'"
      v-for="(product, index) in products"
      :key="index"
    >
      <template v-if="!$attrs.isLoading">
        <slot :product="product" :isScrolling="isScrolling" />
      </template>
      <template v-else>
        <div class="relative lg:mb-6 product-card">
          <div class="relative ">
            <figure
              class="relative h-48 lg:h-56 p-8 bg-white mb-4 overflow-hidden transition duration-150 skeleton-body animate-pulse"
            ></figure>
          </div>
          <div class="text-sm text-gray-600 leading-4"></div>
          <div
            class="w-full whitespace-nowrap overflow-ellipsis overflow-hidden bg-white animate-pulse"
          >
            <span class="opacity-0">c</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CatalogueList',

  props: {
    products: {
      type: Array,
    },
    isTight: {
      type: Boolean,
    },
  },
  data() {
    return {
      isScrolling: false,
    };
  },
  methods: {
    trackScroll: function() {
      this.isScrolling = true;
      window.clearTimeout(scrollTimer);

      let scrollTimer = setTimeout(() => {
        this.isScrolling = false;
      }, 0);
    },
  },
  mounted() {
    document.addEventListener('scroll', this.trackScroll, false);
  },

  beforeUnmount() {
    document.removeEventListener('scroll', this.trackScroll);
  },
};
</script>

<style lang="scss"></style>
