<template>
  <transition name="hover-trap">
    <template v-if="show">
      <div
        class="hover-box flex flex-col bg-primary border border-black border-opacity-30 shadow-lg overflow-hidden"
      >
        <header class="w-full relative">
          <figure class="relative" style="padding: 27.5% 0">
            <div class="absolute inset-0 bg-white ">
              <slot />
            </div>
          </figure>
        </header>
        <article class="flex flex-col flex-1 p-3">
          <h4 class="text-gray-500">Technics Turntable</h4>
          <h2 class="text-xl">{{ product.title }}</h2>
          <div class="relative flex flex-1 flex-col pt-3">
            <div key="1" class="flex flex-col flex-1 switch-item-one">
              <p class="flex-1">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
                consequuntur molestias, fugiat delectus voluptatibus, eaque
                fugit repellat dicta optio eius dolores amet. Dolores neque
                possimus deleniti quam magni reprehenderit aperiam!
              </p>
              <footer class="flex justify-between items-end">
                <div>
                  <span class="text-gray-500 text-sm">Price</span>
                  <span class="text-2xl">{{ product.price }}&euro;</span>
                </div>
                <button @click="handleClick" class="bg-white px-5 py-2">
                  Review Configuration
                </button>
              </footer>
            </div>
          </div>
        </article>
      </div>
    </template>
  </transition>
</template>

<script>
export default {
  name: 'CatPresetHover',
  props: {
    product: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClick: function() {
      this.$router.push({
        name: 'Turntable Configurator',
        params: { ...this.product.ids },
      });
    },
  },
};
</script>
