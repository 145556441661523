<template>
  <!-- Might conflict -->
  <div class="mb-3 w-full">
    <label class="block mb-1 base-input-label text-sm" v-if="label">{{
      label
    }}</label>
    <input
      v-bind="$attrs"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      class="w-full bg-transparent shadow-sm border border-gray-600 flex-1  px-3 py-2"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>

<style>
.base-input-label {
  flex-basis: 5.9rem;
}
</style>
