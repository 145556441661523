<template>
  <div
    style="z-index: 30000"
    class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center"
  >
    <div class="spinner mb-3"></div>
    <div class="whitespace-nowrap shadow-sm px-1 bg-white">
      {{ statusMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'RestLoader',
  data() {
    return {
      statusMessage: '',
      timerId: '',
    };
  },
  props: {
    messages: {
      type: Array,
      default: () => [
        'Connecting to our services',
        'Calling DHL',
        'Writing emails',
      ],
    },
  },
  methods: {
    displayStatus: (() => {
      let i = 0;

      return function() {
        this.statusMessage = this.messages[i];

        i = i < this.messages.length - 1 ? i + 1 : 0;
      };
    })(),
  },

  created() {
    this.statusMessage = this.messages[0];
  },
  mounted() {
    // this.$scroll.block();

    this.timerId = setInterval(this.displayStatus, 800);
  },
  unmounted() {
    clearInterval(this.timerId);
    this.$scroll.unblock();
  },
};
</script>

<style lang="scss">
.spinner {
  --size: 48px;
  --border: 8px;
  --color: #fff;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  position: relative;
  border: var(--border) solid lighten(#000, 5%);
  border-right: var(--border) solid var(--color);
  animation: spin 800ms linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
