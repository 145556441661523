<template>
  <div class="relative mb-3">
    <label class="block mb-1 base-input-label text-sm" v-if="label">{{
      label
    }}</label>
    <select
      ref="select"
      class="relative w-full bg-transparent border border-black border-opacity-60 flex-1 cursor-pointer"
      :value="modelValue"
      v-bind="{
        ...$attrs,
        onChange: ($event) => {
          $emit('update:modelValue', $event.target.value);
        },
      }"
    >
      <option value="selected" selected>Please select one</option>
      <option
        v-for="option in options"
        :value="option"
        :key="option"
        :selected="option === modelValue"
        >{{ option }}</option
      >
    </select>
    <i
      class="absolute top-6 bottom-0 right-0 p-1 border border-black flex items-center justify-center bg-white cursor-pointer pointer-events-none"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-chevron-down"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
        /></svg
    ></i>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleClick() {
      console.log(this.$refs.select);
    },
  },
};
</script>

<style>
select {
  padding: 0.5rem 0.75rem;
  appearance: none;
}
</style>
